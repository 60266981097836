module.exports = {
  background: '#FFFFFF',
  backgroundDark: '#c2dfe3',

  primary: '#66b2b2',
  primaryMedium: '#086375',
  primaryDark: '#00303D',

  sectionHeader: '#004e64',
};
